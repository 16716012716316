import React from "react";
import { Row, Col, Form, Container } from "reactstrap";
import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import { PayOffMethod } from "../constants/Constants";
import InputElement, {
	CurrencyInput,
	IntegerInput,
	SelectorElement,
} from "features/forms/components/InputElement";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider } from "react-hook-form";
import InputWrapper from "features/forms/components/InputWrapper";

const AccountPayoff = () => {
	const { account } = useCurrentAccountStore((state) => state, shallow);

	const schema = yup.object().shape({});

	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<>
			<FormProvider className="" {...methods}>
				<Container fluid style={{ maxWidth: "1200px" }}>
					{" "}
					{/* Limits the overall width */}
					<Form>
						<Row>
							<Col xs="12" md="6" lg="3">
								{" "}
								{/* More compact width */}
								<InputWrapper
									formTitle="PayOff"
									inputComponents={[
										<SelectorElement
											{...methods}
											readOnly={true}
											name="payOffMethod"
											label="PayOff Method"
											value={account.payoffMethod}
											options={PayOffMethod}
											colSize="col-12"
										/>,
										<CurrencyInput
											disableValidation
											readOnly={true}
											value={account.amtFinanced - account.principle}
											name="principle"
											label="Remain Principle"
											type="number"
											colSize="col-12"
										/>,
										<CurrencyInput
											disableValidation
											readOnly={true}
											value={account.unPaidInterest}
											name="interest"
											label="Interest Accrued"
											type="number"
											colSize="col-12"
										/>,
										<CurrencyInput
											disableValidation
											readOnly={true}
											value={account.lateFeeDue}
											name="lateFeeDue"
											label="Remain LateFee"
											type="number"
											colSize="col-12"
										/>,
										<CurrencyInput
											disableValidation
											readOnly={true}
											value={account.debitCreditTotal - account.debitPaid}
											name="remainAdj"
											label="Remain Adj"
											type="number"
											colSize="col-12"
										/>,
										<CurrencyInput
											disableValidation
											readOnly={true}
											value={account.pickupTotal - account.pickupPaid}
											name="remainpickup"
											label="Remain pickup"
											type="number"
											colSize="col-12"
										/>,
										<CurrencyInput
											disableValidation
											readOnly={true}
											value={account.payoff}
											name="payoff"
											label="Total Payoff"
											type="number"
											colSize="col-12"
											labelClass="bigText"
											style={{
												backgroundColor: "green",
												color: "white",
												fontSize: "1.25rem",
												padding: "0.5rem",
											}}
										/>,
									]}
								/>
							</Col>
							<Col xs="12" md="6" lg="3">
								{" "}
								{/* Adjust column size here */}
								<InputWrapper
									formTitle="Past Due"
									inputComponents={[
										<CurrencyInput
											disableValidation
											readOnly={true}
											value={account.principleDue}
											name="principle"
											label="Principle Due"
											type="number"
											colSize="col-12"
										/>,
										<CurrencyInput
											disableValidation
											readOnly={true}
											value={account.interestDue}
											name="interest"
											label="Interest Due"
											type="number"
											colSize="col-12"
										/>,
										<CurrencyInput
											disableValidation
											readOnly={true}
											value={account.lateFeeDue}
											name="lateFeeDue"
											label="LateFee Due"
											type="number"
											colSize="col-12"
										/>,
										<CurrencyInput
											disableValidation
											readOnly={true}
											value={
												account.totalDebitCredit > 0
													? account.totalDebitCredit
													: 0
											}
											name="remainAdj"
											label="Adj Due"
											type="number"
											colSize="col-12"
										/>,
										<CurrencyInput
											disableValidation
											readOnly={true}
											value={account.pickupPastDue}
											name="remainpickup"
											label="Pickup Due"
											type="number"
											colSize="col-12"
										/>,
										<CurrencyInput
											disableValidation
											wholerow
											colSize="col-12"
											readOnly={true}
											value={account.pastDueAmt}
											name="pastDueAmt"
											label="Past Due Amt"
											type="number"
											labelClass="bigText"
											style={{
												backgroundColor: "red",
												color: "white",
												fontSize: "1.25rem",
												padding: "0.5rem",
											}}
										/>,
									]}
								/>
							</Col>
							<Col xs="12" md="6" lg="3">
								{" "}
								{/* Third column, adjust as needed */}
								<InputWrapper
									formTitle="Loan Dates"
									inputComponents={[
										<InputElement
											{...methods}
											readOnly={true}
											value={account.dateOfLastPmt}
											name="date"
											label="Last Paid Date"
											type="date"
											colSize="col-12"
										/>,
										<InputElement
											{...methods}
											readOnly={true}
											value={account.dateOfLastFullPmt}
											name="date"
											label="Last Full Paid Date"
											type="date"
											colSize="col-12"
										/>,
										<InputElement
											{...methods}
											readOnly={true}
											value={account.dateOfLastFullPmt}
											name="date"
											label="Paid Through Date"
											type="date"
											colSize="col-12"
										/>,

										<InputElement
											{...methods}
											readOnly={true}
											value={account.lastScheduledPmtDate}
											name="date"
											label="Previous Scheduled Date"
											type="date"
											colSize="col-12"
										/>,
										<InputElement
											{...methods}
											readOnly={true}
											value={account.nextDueDate}
											name="date"
											label={
												account.nextDueDate > account.finalDueDate
													? "Immediate Date"
													: "Next Scheduled Date"
											}
											type="date"
											colSize="col-12"
										/>,
										<IntegerInput
											{...methods}
											disableValidation
											readOnly={true}
											value={account.daysLate}
											name="daysLate"
											label="Days Late"
											type="number"
											colSize="col-12"
											labelClass="bigText"
											style={{
												backgroundColor: "red",
												color: "white",
												fontSize: "1.25rem",
												padding: "0.5rem",
											}}
										/>,
									]}
								/>
							</Col>
							<Col xs="12" md="6" lg="3">
								<InputWrapper
									formTitle="Pickup Dates "
									inputComponents={[
										<InputElement
											{...methods}
											readOnly={true}
											value={account.pickupLastPmtDate}
											name="date"
											label="Last Paid Date"
											type="date"
											colSize="col-12"
										/>,
										<InputElement
											{...methods}
											readOnly={true}
											value={account.paidThroughDate}
											name="date"
											label="Paid Through Date"
											type="date"
											colSize="col-12"
										/>,
										<InputElement
											{...methods}
											readOnly={true}
											value={account.pickupLastScheduledPmtDate}
											name="date"
											label="Previos Scheduled Date"
											type="date"
											colSize="col-12"
										/>,
										<InputElement
											{...methods}
											readOnly={true}
											value={account.pickupNextDueDate}
											name="date"
											label="Next Scheduled Date"
											type="date"
											colSize="col-12"
										/>,
										<IntegerInput
											{...methods}
											disableValidation
											readOnly={true}
											value={account.pickupDaysLate}
											name="date"
											label="Pickup Days Late"
											type="number"
											colSize="col-12"
											labelClass="bigText"
											style={{
												backgroundColor: "red", // Change the background color
												color: "white", // Change the font color
												fontSize: "20px",
											}}
										/>,
									]}
								/>
							</Col>
						</Row>
					</Form>
				</Container>
			</FormProvider>
		</>
	);
};

export default AccountPayoff;
